import React, { useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Layout from "../../components/layout/Index"
import { graphql } from "gatsby"
import Card from "../../components/commons/card/kumpulan-peraturan"
import VerticalAds from "../../components/commons/ads/vertical-ads/Index"
import { withTrans } from "../../i18n/withTrans"
import { inRangeDate } from "../../utils/date"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const Index = ({ data, location: { href }, t, i18n }) => {
  const language = i18n.language
  const classes = useStyles()
  const aturan = data?.allWpRegulasi?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklan = data?.allWpIklanGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklanBronzeAdSpace = dataIklan?.filter(
    o => o.iklan.slotIklan === "Bronze Ad Space (280 x 360 px)"
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTextTemp, setSearchTextTemp] = useState("")
  const [searchText, setSearchText] = useState("")
  const lengthData = aturan.filter(item =>
    item?.title?.toLowerCase().includes(searchText.toLowerCase())
  ).length

  const pageSize = 7
  const pagesCount = Math.ceil(lengthData / pageSize)

  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  const handlePreviousClick = (e, currentPage) => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextClick = (e, currentPage) => {
    setCurrentPage(currentPage + 1)
  }

  const onSearch = e => {
    e.preventDefault()
    setSearchText(searchTextTemp)
  }

  return (
    <Layout>
      <Container className="regulation-list page-container article-container">
        <Row className="justify-content-between align-items-center mb-4">
          <Col md={6} className="page-title m-0">
            <span>{t("header.centralManagementDuties")}</span>
          </Col>
          <Col md={6} xs={12}>
            <Row className="justify-content-between">
              <Col md={12} className="d-flex justify-content-end">
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("article.enterKeyword")}
                />
                <Button type="submit" onClick={e => onSearch(e)}>
                  {/* {t("search")} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <Row style={{ marginBottom: 20 }}>
              <Col xl={3} md={4} sm={6} xs={12} style={{ padding: 15 }}>
                {dataIklanBronzeAdSpace?.length > 0 &&
                inRangeDate(
                  dataIklanBronzeAdSpace[0].iklan.tanggalJangkaIklanAwal,
                  dataIklanBronzeAdSpace[0].iklan.tanggalJangkaIklanAkhir
                ) &&
                dataIklanBronzeAdSpace[0].iklan
                  .uploadGambarIklanBronzeAdSpace ? (
                  <VerticalAds
                    width={280}
                    height={360}
                    title="B. BRONZE AD SPACE"
                    img_url={
                      dataIklanBronzeAdSpace[0].iklan
                        .uploadGambarIklanBronzeAdSpace.sourceUrl
                    }
                  />
                ) : (
                  <VerticalAds
                    width={280}
                    height={360}
                    title="B. BRONZE AD SPACE"
                  />
                )}
              </Col>
              {aturan && lengthData > 0 ? (
                aturan
                  .filter(item =>
                    item?.title
                      ?.toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((item, i) => (
                    <Col
                      xl={3}
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ padding: 0 }}
                      key={i}
                    >
                      <Card
                        date={item.regulasi.tanggal}
                        title={item?.title}
                        key={item.id}
                        slug={`/regulasi/tugas-pengurus-pusat/${item.slug}`}
                        src={item.regulasi.fileRegulasi?.mediaItemUrl}
                      />
                    </Col>
                  ))
              ) : (
                <Col md={3} style={{ padding: 10 }}>
                  {t("noData")}
                </Col>
              )}
            </Row>
            {aturan && lengthData > 0 && (
              <div className={`${classes.root} d-flex justify-content-center`}>
                <Pagination
                  count={pagesCount}
                  page={currentPage}
                  onChange={handlePageClick}
                  shape="rounded"
                  color="primary"
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {/* <Container className="regulation-detail">
        <Row className="mini-news-container">
          <Col md={9} className="ps-0">
            <div className="d-flex align-items-start mb-4">
              <label className="title">{datafilter[0]?.title}</label>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="primary-label">
                  {moment(datafilter[0]?.tanggal).format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: datafilter[0]?.regulasi?.deskripsi,
              }}
              className="content"
            />
            <iframe
              title="detail-kumpulan-peraturan"
              src={`https://docs.google.com/gview?url=${datafilter[0]?.regulasi?.fileRegulasi?.mediaItemUrl}&embedded=true`}
              className="preview-file"
              frameborder="0"
            ></iframe>
            <div className="d-flex justify-content-between align-items-center mt-3">
              {datafilter[0]?.regulasi?.fileRegulasi && (
                <a
                  download
                  target="_blank"
                  href={datafilter[0]?.regulasi.fileRegulasi.mediaItemUrl}
                >
                  <Button className="custom-btn-primary">
                    Download (
                    {Number(
                      datafilter[0]?.regulasi.fileRegulasi.fileSize / 1000000
                    ).toFixed(1)}
                    ) MB
                  </Button>
                </a>
              )}
            </div>
          </Col>
          <Col md={3} className="ads-parent">
            {dataIklanBronzeAdSpace?.length > 0 &&
            inRangeDate(
              dataIklanBronzeAdSpace[0].iklan.tanggalJangkaIklanAwal,
              dataIklanBronzeAdSpace[0].iklan.tanggalJangkaIklanAkhir
            ) &&
            dataIklanBronzeAdSpace[0].iklan.uploadGambarIklanBronzeAdSpace ? (
              <VerticalAds
                width={280}
                height={360}
                title="B. BRONZE AD SPACE"
                img_url={
                  dataIklanBronzeAdSpace[0].iklan.uploadGambarIklanBronzeAdSpace
                    .sourceUrl
                }
              />
            ) : (
              <VerticalAds
                width={280}
                height={360}
                title="B. BRONZE AD SPACE"
              />
            )}
          </Col>
        </Row>
      </Container> */}
    </Layout>
  )
}

export const query = graphql`
  {
    allWpRegulasi(
      filter: { regulasi: { jenisRegulasi: { eq: "Tugas-Tugas Pengurus" } } }
    ) {
      nodes {
        id
        slug
        language {
          id
          code
        }
        date
        title
        regulasi {
          fileRegulasi {
            mediaItemUrl
            fileSize
          }
          jenisRegulasi
          tanggal
          deskripsi
        }
      }
    }
    allWpIklanGks(sort: { fields: date, order: DESC }) {
      nodes {
        title
        iklan {
          slotIklan
          deskripsiIklan
          letakIklan {
            bronzeAdSpace
            goldA
            premium
            silverAdSpace
            goldB
          }
          uploadGambarIklanBronzeAdSpace {
            sourceUrl
          }
          tanggalJangkaIklanAkhir
          tanggalJangkaIklanAwal
        }
        date
        language {
          code
        }
      }
    }
  }
`

export default withTrans(Index)
